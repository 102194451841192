@charset 'utf-8';
// Main modules
@import 'settings';
@import 'foundation';
@import 'motion-ui';

// other add-ons
// @import 'fontawesome';
// @import 'foundation-font-awesome-buttons';
// @import 'foundation-icons';

// Foundation Components to include. These are actually defined in the above imports (I think)
// Comment out those not being used for faster load times
@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
// @include foundation-switch; */
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;

// @include foundation-font-awesome-buttons;


// My stuff - is it an include or an import? Hmmm...

@import 'bt/bt-typography';
@import 'bt/bt-footer';
@import 'bt/bt-programs';
@import 'bt/bt-navigation';
@import 'bt/bt-hero';
@import 'bt/bt-forms';
@import 'bt/ajx-spamblocker';
@import 'bt/bt-icons';
@import 'bt/bt-media-object';
@import 'components/pricingtable';

/************************************************************************************
GENERAL
************************************************************************************/

$pageheader-background: #0C379F !default;
$pagealert-background: #2199e8 !default;

$titlebar-background: $bt-menu-bar !important;

.feltpen {
  background-color: lighten(yellow, 35%);
}

.underline--dotted {
  border-bottom: 2px  dashed;
}

/* Soft Yellow Highlight for text emphasis */
hr {
  height: 0px;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-top-style: dashed;
  border-top-color: #ADC6EF;
}

.row {
  max-width: 1200px;
}

.size-small {
  font-size: rem-calc(12)
}

h1, h3, h5 {
  color: $bt-primary;
}

h2, h4, h6 {
  color: $bt-secondary-headers;
}
.dropdown li .divider {border-bottom: 1px solid #900;}

.sharing-icon img {
  height: 75px !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  }

.image-card {
  border: 1px solid #ddd;
}

.image-card__image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 20rem;
  width: 100%;

  background-color: lightgrey;
  text-align: center;
}

.image-card__image > img {
  max-height: 100%;
}

.image-card-caption {
  margin: 0.75rem 0.75rem 1rem 0.75rem;
}

.image-card-caption__title {
  margin-bottom: 0.5rem;
  color: #222222;
  font-size: 1.25rem;
  line-height: 1.625rem;
}

.image-card-caption__subtitle {
  color: #4a4a4a;
  font-size: 1rem;
  line-height: 1.125rem;
}

.cookie-banner {
	position: fixed;
	bottom: 40px;
	left: 10%;
  right: 10%;
  width: 80%;
  padding: 5px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eee;
  border-radius: 5px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.close {
  height: 20px;
  background-color: #777;
  border: none;
  color: white;
  border-radius: 2px;
  cursor: pointer;
}


/************************************************************************************
TOP OF PAGES
************************************************************************************/
.pageheader {
  background: $pageheader-background;


}

.info {
  background-color: $pagealert-background;
  height: 2.5rem;
  padding-top: .2rem;
  h4 {
    a:link {
      color: #ffffff;
    }
    a:hover {
      color: black !important;
    }
    a:visited {
      color: white;
    }
  }
}

.cookie-banner {
  position: fixed;
  bottom: 40px;
  left: 10%;
  right: 10%;
  width: 80%;
  padding: 5px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eee;
  border-radius: 5px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
.close {
  height: 20px;
  background-color: #777;
  border: none;
  color: white;
  border-radius: 2px;
  cursor: pointer;
}



/************************************************************************************
MAIN SECTION
************************************************************************************/
.button-group {
  position: relative;
  display: inline-block;
  align-items: center;
  margin: 1.1rem 0;
  &:before {
    content: attr(data-grouptype);
    z-index: 99999;
    color: #999;
    display: block;
    font-size: 1rem;
    padding: 0.3em;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    left: 40.5%;
    top: 0.35rem;
  }
  button + button {
    margin-left: 5px;
  }
}

$button-group-option-font-size: .2rem; // controls size of circle and text of option
$button-group-option-button-gap: 0.35rem;
$button-group-option-button-fontsize: rem-calc(18); // controls size of button and text of button

.button-group-option {
  position: relative;
  align-items: center;
  width: 100%;
  @include button-group;
  @include button-group-expand('.button');

  .button {
    font-size: .2rem;
  }

  &:before {
    content: attr(data-grouptype);
    z-index: 1;
    color: $dark-gray;
    display: inline-block;
    font-size: $button-group-option-font-size;
    padding: 0.25em;
    border-radius: 5000px;
    background: $white;
    @include absolute-center;
  }

  .button + .button {
    margin-left: 0.35rem;
  }
}


.tabs-title {
  a {
    font-size: 13px;
    font-weight: $global-weight-bold;
  }

}

#Community {

  tbody {
    border: 0px;
    border-color: white;
    border-collapse: collapse;
  }

  tr {
    background-color: white;
  }

}

.licensing {

    tbody {
    border: 0px;
    border-color: darkgray;
    border-collapse: collapse;
    background-color: #dddbdb;
  }

  tr {
    background-color: #dddbdb;
  }
  .topDiv {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 500px;
    border: 1px solid #A0A0A0;
    background-color: white;
    margin-left: -250px;
    margin-top: -250px;
    box-shadow: 10 5 5px black;
    z-index: 3;
  }

  .topDivForIFrame {
    position: absolute;
    top: 0%;
    left: 50%;
    width: 500px;
    border: 1px solid #A0A0A0;
    background-color: white;
    margin-left: -250px;
    margin-top: 0;
    box-shadow: 10 5 5px black;
    z-index: 3;
  }

  .center {
    margin: auto;
    width: 95%;
  }

  .licenseHeader {
    font-size: 1.5em;
    color: darkslategrey;
    text-align: center;
    border-radius: 1px 3px 0 0;
    background: linear-gradient(#dddbdb, #ffffff);
    height: 100px;
    margin-bottom: 20px;
  }

  .licenseHeader2 {
    font-size: 1em;
    font-weight: 500;
    color: #1a1919;
    background: #ffffff;
    text-align: center;
    height: 30px;
    margin-bottom: 10px;
    margin-top: 20px;
    margin-left: 10px;
  }

  input[type=username], input[type=password] {
    width: 100%;
    font: 0.875em 'Segoe UI', Helvetica, 'Droid Sans', Tahoma, Geneva, sans-serif;
    margin-bottom: 18px;
    height: 34px;
    color: #1a1919;
    /*  background: white; */
    border: 2px solid #c2d1dc;
    border-radius: 3px;
    padding-left: 10px;
    line-height: 34px;
  }

  input[type=username]:focus {
    border-color: #95ddf5;
  }

  .placeholderSpan {
    font: 0.875em 'Segoe UI', Helvetica, 'Droid Sans', Tahoma, Geneva, sans-serif;
    line-height: 34px;
    color: #b9b2b2;
    background: white;
    padding-left: 8px;
    vertical-align: middle;
    margin-top: 3px;
    position: absolute;
    z-index: -1;
    left: 30px;
  }

  .licenseFooter {
    margin: auto;
    width: 90%;
    text-align: left;
  }

  .licenseFooter .button {
    font: 1em 'Segoe UI', Helvetica, 'Droid Sans', Tahoma, Geneva, sans-serif;
    float: right;
    padding: 11px 25px;
    color: #fff;
    text-shadow: 0px 1px 0 rgba(0, 0, 0, 0.25);
    background: darkorange;
    border: 1px solid #ee8505;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: inset 0 0 2px rgba(256, 256, 256, 0.75);
    -moz-box-shadow: inset 0 0 2px rgba(256, 256, 256, 0.75);
    -webkit-box-shadow: inset 0 0 2px rgba(256, 256, 256, 0.75);
  }

  .licenseFooter .button:hover {
    background: #fc8412;
    border: 1px solid rgba(256, 256, 256, 0.75);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
  }

  .licenseFooter .button:focus {
    position: relative;
    bottom: -1px;
    background: darkorange;
    box-shadow: inset 0 1px 3px rgba(256, 256, 256, 0.75);
    -moz-box-shadow: inset 0 1px 6px rgba(256, 256, 256, 0.75);
    -webkit-box-shadow: inset 0 1px 6px rgba(256, 256, 256, 0.75);
  }

  .licenseFooter .poweredBy {
    color: #2c2d35;
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
    text-transform: lowercase;
  }

  .message {
    font: 0.875em 'Segoe UI', Helvetica, 'Droid Sans', Tahoma, Geneva, sans-serif;
    margin-bottom: 15px;
    color: #1a1919;
    background: white;
  }

  .validatorError {
    font: bold 1.5em 'Segoe UI', Helvetica, 'Droid Sans', Tahoma, Geneva, sans-serif;
    color: red;
    vertical-align: middle;
    text-align: right;
    padding-left: 10px;
  }
}

/************************************************************************************
ENDORSEMENTS
************************************************************************************/
.quote {
  background-image: url("/assets/img/quotes.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  padding-top: 10px;
}
.smallsignature {
  text-align: right;
  font-size: .9em;
  font-family: "Comic Sans MS", cursive;
  font-style: italic;
}

.no-js [data-responsive-toggle] {
  display: none;
}




.cookieConsentContainer {
	z-index: 999;
	width: 350px;
	min-height: 20px;
	box-sizing: border-box;
	padding: 30px 30px 30px 30px;
	background: #232323;
	overflow: hidden;
	position: fixed;
    bottom: 30px;
	right: 30px;
	display: none;
}
.cookieConsentContainer .cookieTitle a {
	font-family: OpenSans, arial, "sans-serif";
	color: #FFFFFF;
	font-size: 22px;
	line-height: 20px;
	display: block;
}
.cookieConsentContainer .cookieDesc p {
	margin: 0;
	padding: 0;
	font-family: OpenSans, arial, "sans-serif";
	color: #FFFFFF;
	font-size: 13px;
	line-height: 20px;
	display: block;
	margin-top: 10px;
} .cookieConsentContainer .cookieDesc a {
	font-family: OpenSans, arial, "sans-serif";
	color: #FFFFFF;
	text-decoration: underline;
}
.cookieConsentContainer .cookieButton a {
	display: inline-block;
	font-family: OpenSans, arial, "sans-serif";
	color: #FFFFFF;
	font-size: 14px;
	font-weight: bold;
	margin-top: 14px;
	background: #000000;
	box-sizing: border-box;
	padding: 15px 24px;
	text-align: center;
	transition: background 0.3s;
}
.cookieConsentContainer .cookieButton a:hover {
	cursor: pointer;
	background: #3E9B67;
}

@media (max-width: 980px) {
	.cookieConsentContainer {
		bottom: 0px !important;
		left: 0px !important;
		width: 100%  !important;
	}
}
