/************************************************************************************
NAVAGATION
************************************************************************************/
$topbar-background: $bt-menu-bar !important;
$menu-background: $bt-menu-bar;
$secondary-bar-background: $bt-secondary-bar !important;
$secondary-menu: #0c8cba;

.title-bar {
  background: $menu-background;
  padding: 0rem;
}

.menu-text.product {
  color: #ffffff;
}

.menu.product {
  background-color: $bt-secondary-bar !important;
}

.menu.dealer {
  background-color: $bt-secondary-headers;
}

.menu.main {
  background-color: $menu-background;
}

.top-bar.dealer {
  background: $bt-secondary-headers;
  padding: 0rem;
}

.top-bar {
  background: $menu-background;
  padding: 0rem;

  i {
    font-size: 1.2rem;
    padding-bottom: 4px;
  }
  /* MAIN Menu Bar */
  ul {
    li {
      background: $menu-background;

      a {
        color: white;
        font-size: .9rem;

        &:hover {
          color: #4980e2;
          background: lighten($menu-background, 10%);
          text-decoration: none;
        }
      }

      /* And the dropdowns  */
      ul {
        li {
          color: $secondary-color;
          font-weight: $global-weight-bold;

          a {
            color: #3a3a3a;
            font-weight: $global-weight-normal;
          }
          a:hover {
            color: #0519b8;
            text-decoration: none;
          }

        }
      }
    }
  }
}

.top-bar.product {
  background: $bt-secondary-bar !important;
  padding: 0rem;

  ul {
    li {
      background: $secondary-bar-background !important;
      background-color: $bt-secondary-bar !important;

      a {
        color: white;
        font-size: .9rem;

        &:hover {
          color: #4980e2;

          background: lighten($menu-background, 10%);
          text-decoration: none;
        }
      }
    }
  }
}

.menu.submenu {
  li {
    background: lighten($menu-background, 80%);

    a:hover {
      background: lighten($menu-background, 60%);
      text-decoration: none;
    }

    li {
      background: lighten($secondary-menu, 50%);

      a:hover {
        background: lighten($secondary-menu, 42%);
        text-decoration: none;
      }
    }
  }
}

.top-bar.menu-text {
  color: #fff;
  font-weight: $global-weight-bold;

  @media only screen and (max-width: 40em) {
    display: none !important;

  }
}


@media only screen and (min-width: 40em) {
  .top-bar.menu:last-child {
    border-left: 1px solid #4e4e4e;

  }

  .top-bar.menu:first-child {
    border-left: none;
  }

  .menu {
    li:not(:last-child) {
      border-right: 1px solid #4e4e4e;
    }
  }

}

.dropdown.menu .submenu {
  border: 0px;
  color: #dee7f5;
}

.dropdown.menu {
  border: none;
  color: #dee7f5;
  //  height: 1rem;
}

.dropdown.menu .is-dropdown-submenu-parent.is-right-arrow > a::after {
  border-color: #3a3a3a transparent transparent;

}

.is-drilldown-submenu-parent > a::after {
  border-color: transparent transparent transparent #fff;
}

.js-drilldown-back::before {
  border-color: transparent #fff transparent transparent;
}

.is-dropdown-submenu-parent {
  position: relative;

  a::after {
    float: $global-right;
    margin-top: 3px;
    margin-#{$global-left}: 10px;
  }
}

.is-down-arrow > a::after {
  @include css-triangle(5px, $dropdownmenu-arrow-color, down);
  position: absolute;
  top: rem-calc(18px) + rem-calc(get-side($menu-item-padding, top)) !important;
  #{$global-right}: 5px;
}

.vertical.menu {
  border: none;

  .menu-text {
    color: #000088;
  }

  .li {
    li:not(:last-child) {
      border-right: 0px;
      border-left: 0px;;
    }
  }
}

.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}