/* http://metroize.com/zurb/ */

$gainsboro: #DDD;
$oil: #333;
$smoke:#F6F6F6;
$monsoon: #777;
$popular: #43ac6a;

// We use this to control the border color
$price-table-border: solid 1px $gainsboro;
$price-table-border-popular: solid 3px $popular;

// We use this to control the bottom margin of the pricing table
$price-table-margin-bottom: rem-calc(20);

// We use these to control the title styles
$price-title-bg: $oil;
$price-title-padding: rem-calc(15 20);
$price-title-align: center;
$price-title-color: $smoke;
$price-title-weight: $global-weight-normal;
$price-title-size: rem-calc(16);
$price-title-font-family: $body-font-family;

// We use these to control the price styles
$price-money-bg: $smoke;
$price-money-padding: rem-calc(15 20);
$price-money-align: center;
$price-money-color: $oil;
$price-money-weight: $global-weight-normal;
$price-money-size: rem-calc(20);
$price-money-font-family: $body-font-family;

// We use these to control the description styles
$price-bg: $white;
$price-desc-color: $monsoon;
$price-desc-padding: rem-calc(15);
$price-desc-align: center;
$price-desc-font-size: rem-calc(12);
$price-desc-weight: $global-weight-normal;
$price-desc-line-height: 1.4;
$price-desc-bottom-border: dotted 1px $gainsboro;

// We use these to control the list item styles
$price-item-color: $oil;
$price-item-padding: rem-calc(15);
$price-item-align: center;
$price-item-font-size: rem-calc(14);
$price-item-weight: $global-weight-normal;
$price-item-bottom-border: dotted 1px $gainsboro;

// We use these to control the CTA area styles
$price-cta-bg: $white;
$price-cta-align: center;
$price-cta-padding: rem-calc(20 20 0);

// @mixins
//
// We use this to create the container element for the pricing tables
@mixin pricing-table-container {
  border: $price-table-border;
  margin-left: 0;
  margin-bottom: $price-table-margin-bottom;

  & * {
    list-style: none;
    line-height: 1;
  }
}

@mixin pricing-table-container-popular {
  border: $price-table-border-popular;
  margin-left: 0;
  margin-bottom: $price-table-margin-bottom;

  & * {
    list-style: none;
    line-height: 1;
  }
}

// @mixins
//
// We use this mixin to create the pricing table title styles
@mixin pricing-table-title {
  background-color: $price-title-bg;
  color: $price-title-color;
  font-family: $price-title-font-family;
  font-size: $price-title-size;
  font-weight: $price-title-weight;
  padding: $price-title-padding;
  text-align: $price-title-align;
}

// @mixins
//
// We use this mixin to control the pricing table price styles
@mixin pricing-table-price {
  background-color: $price-money-bg;
  color: $price-money-color;
  font-family: $price-money-font-family;
  font-size: $price-money-size;
  font-weight: $price-money-weight;
  padding: $price-money-padding;
  text-align: $price-money-align;
}

// @mixins
//
// We use this mixin to create the description styles for the pricing table
@mixin pricing-table-description {
  background-color: $price-bg;
  border-bottom: $price-desc-bottom-border;
  color: $price-desc-color;
  font-size: $price-desc-font-size;
  font-weight: $price-desc-weight;
  line-height: $price-desc-line-height;
  padding: $price-desc-padding;
  text-align: $price-desc-align;
}

// @mixins
//
// We use this mixin to style the bullet items in the pricing table
@mixin pricing-table-bullet {
  background-color: $price-bg;
  border-bottom: $price-item-bottom-border;
  color: $price-item-color;
  font-size: $price-item-font-size;
  font-weight: $price-item-weight;
  padding: $price-item-padding;
  text-align: $price-item-align;
}

@mixin pricing-table-bullet-popular {
  background-color: $popular;
  border-bottom: $price-item-bottom-border;
  color: $price-item-color;
  font-size: $price-item-font-size;
  font-weight: $price-item-weight;
  padding: $price-item-padding;
  text-align: $price-item-align;
}


// @mixins
//
// We use this mixin to style the CTA area of the pricing tables
@mixin pricing-table-cta {
  background-color: $price-cta-bg;
  padding: $price-cta-padding;
  text-align: $price-cta-align;
}

/* Pricing Tables */
.pricing-table {
    @include pricing-table-container;

    .title { @include pricing-table-title; }
    .price { @include pricing-table-price; }
    .description { @include pricing-table-description; }
    .bullet-item { @include pricing-table-bullet; }
    .cta-button { @include pricing-table-cta; }
}


/* Pricing Tables */
.pricing-table-popular {
    @include pricing-table-container-popular;
    .bullet-popular {@include pricing-table-bullet-popular; }
    .title { @include pricing-table-title; }
    .price { @include pricing-table-price; }
    .description { @include pricing-table-description; }
    .bullet-item { @include pricing-table-bullet; }
    .cta-button { @include pricing-table-cta; }
}