
/************************************************************************************
FOOTER
************************************************************************************/

.newsletter-signup {
  padding: 1rem;
  background-color: lighten($bt-newsfooter, 5%);

}

.footer {
  background-color: $bt-footer;
  text-align: center;
  font-size: 1.2rem;
  padding: 2rem;

  .inline-list a {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-color: $bt-footer;
    border-radius: 2px;
    font-size: 1.5rem;
    color: $bt-primary;
    text-align: center;
    line-height: 35px;
    margin: 0 3px 0 0;

    .social {
      font-size: 2rem;

      i {
        position: absolute;
        background-color: $bt-primary;
        color: $white;
        font-size: 1rem;
        border-radius: 50%;
        line-height: 1;
        margin: 0 0 0 0;
        vertical-align: middle;
        padding: .25em .45em;
      }
    }
  }

  .contact {
    list-style-type: none;
    margin: 0;
    text-align: left;
    color: $bt-primary;

    li {
      margin-bottom: 2rem;

      p {
        padding-left: 5rem;
      }
    }

    i {
      position: absolute;
      background-color: $bt-primary;
      color: $white;
      font-size: 1rem;
      border-radius: 50%;
      line-height: 1;
      margin: 0 0 0 -4rem;
      vertical-align: bottom;
      padding: .25em .45em;
    }
  }

  .logo {
    color: $black;
    font-size: 1.5rem;
    font-style: italic;
    text-align: left;

    a {
      color: $black;

      &:hover {
        color: #051dd5;
        text-decoration: underline;

      }
    }

    i {
      margin-right: 0.5rem;
    }
  }

  .btaddress {
    color: $bt-primary;
    font-size: 0.5rem;
    margin: 0 0 1rem 0;
    text-align: left;
  }

  .copywrite {
    color: $dark-gray;
    font-size: 0.875rem;
    margin: 0 0 1rem 0;
  }

  .about {
    color: $bt-primary;
    font-size: 0.875rem;
    margin: 0 0 1rem 0;
    text-align: left;
  }

  .footer-menu {
    background-color: $bt-footer;
    color: darken($dark-gray, 50%);
    font-size: 0.875rem;
    margin: 0 0 1rem 0;
    text-align: left;
    font-weight: $global-weight-bold;

    li {
      list-style-position: inside;
      font-weight: normal;
      color: darken($dark-gray, 50%);
      margin: 0;
      font-size: 0.875rem;

      a {
        color: $bt-primary;
      }

      a:hover {
        color: #051dd5;
        text-decoration: underline;
      }
    }
  }

  .footer-links a {
    list-style: none;
    font-weight: normal;
    color: darken($dark-gray, 10%);
    padding: 3rem 0 2rem;
    margin: 0;
    font-size: 0.875rem;

    &::after {
      content: "•";
      padding: 0 0.2rem 0 0.4rem;
    }

    &:last-child::after {
      content: "";
      padding: 0 0.4rem 0 0.8rem;
    }

    &:hover {
      color: #051dd5;
      text-decoration: underline;
    }

  }

  @media only screen and (min-width: 40.063em) {

    p {
      text-align: left;
    }

    .social {
      text-align: left;
      margin: .25em;

      i {
        position: absolute;
        background-color: $bt-primary;
        color: $white;
        font-size: 1.75rem;
        border-radius: 50%;
        line-height: 1;
        margin: 0 .25em 0 .25em;
        vertical-align: middle;
        padding: .15em .35em;
      }
    }

    .contact {
      text-align: left;
      > i {
        margin-right: 1rem;
      }
    }

  }
}
