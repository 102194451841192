/************************************************************************************
ICON SETTINGS
************************************************************************************/

i.fi-checkbox {
  color: navy;
  font-size: 1.3rem;
  vertical-align: middle;
}

i.fi-x-circle {
  color: darkred;
  font-size: 1.3rem;
  vertical-align: middle;
}

i.fi-social-pinterest {
  color: darkred;
  font-size: 2rem;

}

i.fi-download {
  p {
    color: darkgreen;
    font-size: 1.3rem;
    padding: 0;
  }
  menu {
    color: white;
  }

}

i.fi-credit-card {
  color: darkgreen;
  font-size: 1.3rem;

}

i.fi-arrow-right {
  color: skyblue;
  font-size: 1.5rem;
  vertical-align: middle;
}

i.fi-info {
  color: #3a3a3a;
  font-size: 1.5rem;
  vertical-align: middle;
}

.size-12 {
  font-size: 12px;
}

.size-14 {
  font-size: 14px;
}

.size-16 {
  font-size: 16px;
}

.size-18 {
  font-size: 18px;
}

.size-21 {
  font-size: 21px;
}

.size-24 {
  font-size: 24px;
}

.size-36 {
  font-size: 36px;
}

.size-48 {
  font-size: 48px;
}

.size-60 {
  font-size: 60px;
}

.size-72 {
  font-size: 72px;
}

// Samples from the Zurb website (renamed to match the look)
.redglow {
  font-size: 70px;
  color: #E33100;
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #E33100, 0 0 30px #E33100, 0 0 40px #E33100, 0 0 50px #E33100, 0 0 75px #E33100;
}

.orange-inshadow {
    font-size: 70px;
    color: rgba(245,159,26,0.8);
    text-shadow: 1px 4px 6px #FFF,0 0 0 #000,1px 4px 6px #FFF;
}

.simple-green {
    font-size: 70px;
    color: #B8D30B;
}

.blue-3d {
    font-size: 70px;
    color: #2DAEBF;
    text-shadow: 0px 1px 0px #0092b3,0px 2px 0px #0087a6,0px 3px 0px #008099,0px 4px 0px #00758c,0px 5px 0px #555,0px 6px 0px #006a80,0px 0px 0px #006073,0px 8px 7px #005566;
}

.red-dropshadow {
    font-size: 70px;
    text-shadow: 0 1px 3px rgba(0,0,0,0.25);
    color: #A9014B;
}


.fb-inshadow {
    font-size: 70px;
    color: rgba(20, 56, 153, 0.8);
    text-shadow: 1px 4px 6px #FFF,0 0 0 #000,1px 4px 6px #FFF;
}


.idea-inshadow {
    font-size: 70px;
    color: rgba(255, 176, 88, 0.8);
    text-shadow: 1px 4px 6px #FFF,0 0 0 #000,1px 4px 6px #FFF;
}


.pencil-inshadow {
    font-size: 70px;
    color: rgba(153, 68, 47, 0.8);
    text-shadow: 1px 4px 6px #FFF,0 0 0 #000,1px 4px 6px #FFF;
}


.download-inshadow {
    font-size: 70px;
    color: rgba(58, 58, 58, 0.8);
    text-shadow: 1px 4px 6px #FFF,0 0 0 #000,1px 4px 6px #FFF;
}