
/************************************************************************************
ORBIT HERO
************************************************************************************/
.orbit-container {
  width: 100%;
  height: 100% !important;
}

.orbit-caption {
  font-weight: $global-weight-bold;
}

.orbit-slides-container {
  width: 100%;
  height: 100% !important;
}

ul[data-orbit] li {
  width: 100%;
  height: 100%;
  background-size: cover;

}

.orbit {
  h1, h2, p {
    color: #fff;
    font-family: 'Open Sans', sans-serif;
  }
  .y-center {
    position: relative;
  }
}