
/************************************************************************************
SPAM BLOCKER
************************************************************************************/
.AJXSBbottom img {
  vertical-align: bottom;
  border: 0;
}

.AJXSBtop img {
  vertical-align: top;
  border: 0;
}

.AJXSBtexttop img {
  vertical-align: text-top;
  border: 0;
}

.AJXSBbaseline img {
  vertical-align: baseline;
  border: 0;
}

.AJXSBmiddle img {
  vertical-align: middle;
  border: 0;
}

.AJXSBtextbottom img {
  vertical-align: text-bottom;
  border: 0;
}
