
/************************************************************************************
FOOTER
************************************************************************************/

.program {
  background-color: $bt-program;
  background-image: url("/assets/img/sky.jpg");
  padding: 1rem;

}

.program-details {
  background-color: $bt-programdetails;


}
