.size-small {
  font-size: rem-calc(12)
}

h1, h3, h5 {
  color: $bt-primary;
}

h2, h4, h6 {
  color: $bt-secondary-headers;
}

 h4, h5 {
  // font-weight: $global-weight-bold;//
 }

h4.price  {
  vertical-align: top;
  font-weight: 900;
  line-height: 1;
  margin-bottom: .2rem;
}

h5.price {
  vertical-align: top;
  color: #2199e8;
  line-height: 1;
  font-weight: 500 !important;

}


h5.sale {
  vertical-align: top;
  color: #e83b52 !important;
  line-height: 1;
  font-weight: 700;

}

// Keystrokes
kbd {
  padding: $keystroke-padding;
  margin: 0;
  background-color: $keystroke-background;
  color: $keystroke-color;
  font-weight: $global-weight-bold ;

  @if has-value($keystroke-radius) {
    border-radius: $keystroke-radius;
  }
}

p.dealerprice {
  color: $bt-secondary-headers;
  font-weight: $global-weight-bold;
  text-align: right;

}

p.mapprice {
  color: black;
  font-weight: $global-weight-bold;
  text-align: right;

}

p.msrpprice {
  color: black;
  text-align: right;
}

